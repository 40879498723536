<template>
  <validation-observer #default="{handleSubmit}">
    <b-form
      class="mb-1"
      @submit.prevent="handleSubmit(search)"
    >
      <!-- Country -->
      <country-select
        rules="required"
        :country.sync="searchQuery.country"
      />

      <!-- City -->
      <city-select
        rules="required"
        :country="searchQuery.country"
        :city.sync="searchQuery.city"
      />

      <!-- From -->
      <date-time
        id="from-date"
        :datetime.sync="searchQuery.fromDate"
        name="From Date"
        rules="required"
        :config="{ dateFormat: 'Y-m-d'}"
      />

      <!-- To -->
      <date-time
        id="to-date"
        :datetime.sync="searchQuery.toDate"
        name="To Date"
        rules="required"
        :config="{ dateFormat: 'Y-m-d',minDate:searchQuery.fromDate}"
      />

      <!-- Timezone -->
      <custom-vue-select
        id="timezone-select"
        label="Timezone"
        rules="required"
        placeholder="Select timezone"
        :value-reducer="option=>option.name"
        :options="timezones"
        text-field="name"
        :disabled="!searchQuery.city"
        :selected.sync="searchQuery.timezone"
      />

      <!-- Calculation Method -->
      <custom-vue-select
        id="calc-method"
        label="Calculation Method"
        placeholder="Select calculation method"
        rules="required"
        :value-reducer="option=>option.methodCode"
        :options="calculationMethods"
        :selected.sync="searchQuery.calculationMethod"
        text-field="methodName"
        :disabled="!searchQuery.city"
      />

      <div v-if="searchQuery.calculationMethod === 'CUSTOM'">
        <text-input
          id="fajr-angle"
          name="Fajr Angle"
          rules="required"
          type="Number"
          :text.sync="searchQuery.fajrAngle"
        />
        <text-input
          id="isha-angle"
          name="Isha Angle"
          rules="required"
          type="Number"
          :text.sync="searchQuery.ishaAngle"
        />
      </div>

      <!-- Madhab Method -->
      <custom-vue-select
        id="madhab-method"
        label="Madhab Method"
        placeholder="Select madhab method"
        rules="required"
        :value-reducer="option=>option.name"
        :options="madhabMethods"
        text-field="name"
        :selected.sync="searchQuery.madhabMethod"
        :disabled="!searchQuery.city"
      />

      <!-- High Latitude Rule Methods -->
      <custom-vue-select
        id="HLRM"
        label="High Latitude Rule Methods"
        placeholder="Select High Latitude Rule Methods"
        rules="required"
        :value-reducer="option=>option.name"
        :options="highLatitudeRuleMethods"
        text-field="name"
        :selected.sync="searchQuery.highLatitudeRuleMethods"
        :disabled="!searchQuery.city"
      />

      <!-- Search-BTN -->
      <div>
        <b-button
          variant="primary"
          type="submit"
        >
          Search
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'
import DateTime from '../common/FormInputs/DateTime.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import CitySelect from './CitySelect.vue'
import CountrySelect from './CountrySelect.vue'

export default {
  components: {
    CountrySelect,
    CitySelect,
    CustomVueSelect,
    TextInput,
    DateTime,
  },
  props: {
    searchQuery: { type: Object, default: () => {} },
  },
  data() {
    return {
      highLatitudeRuleMethods: [],
      madhabMethods: [],
      calculationMethods: [],
      timezones: [],
    }
  },
  watch: {
    'searchQuery.city': function () {
      this.resetSettings()
      this.getSettings()
    },
  },
  methods: {
    getSettings() {
      if (this.searchQuery.city && this.searchQuery.country) {
        this.$portalUsers.get('/internalops/azan/settings', {
          params: {
            city: this.searchQuery.city,
            country: this.searchQuery.country,
          },
        }).then(res => {
          this.setSettings(res.data.data)
          this.setDefaultSettings()
        })
      }
    },
    setSettings(settings) {
      this.highLatitudeRuleMethods = settings.highLatitudeRuleMethods
      this.madhabMethods = settings.madhabMethods
      this.calculationMethods = settings.calculationMethods
      this.timezones = settings.timezones
    },
    setDefaultSettings() {
      this.$set(this.searchQuery, 'highLatitudeRuleMethods', this.getDefaultSettingName(this.highLatitudeRuleMethods).name)
      this.$set(this.searchQuery, 'madhabMethod', this.getDefaultSettingName(this.madhabMethods).name)
      this.$set(this.searchQuery, 'calculationMethod', this.getDefaultSettingName(this.calculationMethods).methodCode)
      this.$set(this.searchQuery, 'timezone', this.getDefaultSettingName(this.timezones).name)
    },
    getDefaultSettingName(options) {
      return options.find(option => option.default) || {}
    },
    resetSettings() {
      this.$set(this.searchQuery, 'highLatitudeRuleMethods', '')
      this.$set(this.searchQuery, 'madhabMethod', '')
      this.$set(this.searchQuery, 'calculationMethod', '')
      this.$set(this.searchQuery, 'timezone', '')
      this.$set(this.searchQuery, 'fajrAngle', '')
      this.$set(this.searchQuery, 'ishaAngle', '')
    },
    search() {
      this.$emit('search')
    },
  },
}
</script>

<style>

</style>
