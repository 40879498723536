<template>
  <custom-vue-select
    id="city-select"
    label="City"
    placeholder="Select City"
    :rules="rules"
    :value-reducer="option=>option.city"
    :options="cities"
    text-field="city"
    :data-provider="getCities"
    :selected.sync="cityProxy"
  />

</template>
<script>
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'CountrySelect',
  components: { CustomVueSelect },
  props: {
    rules: { type: String, default: '' },
    city: { type: String, default: '' },
    country: { type: String, default: '' },
  },
  data() {
    return {
      cities: [],
    }
  },
  computed: {
    cityProxy: {
      get() {
        return this.city
      },
      set(value) {
        this.$emit('update:city', value)
      },
    },
  },
  watch: {
    country() {
      this.cityProxy = ''
      this.getCities()
    },
  },
  created() {
    this.getCities()
  },
  methods: {
    getCities(query) {
      this.$portalUsers.get('/internalops/azan/city', {
        params: {
          country: this.country,
          city: query,
        },
      }).then(res => {
        this.cities = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
