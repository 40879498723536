<template>
  <custom-vue-select
    id="country-select"
    label="Country"
    placeholder="Select Country"
    :rules="rules"
    :value-reducer="option=>option.country"
    :options="countries"
    text-field="country"
    :data-provider="getCountries"
    :selected.sync="countryProxy"
  />
</template>
<script>
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'CountrySelect',
  components: {
    CustomVueSelect,
  },
  props: {
    rules: { type: String, default: '' },
    country: { type: String, default: '' },
  },
  data() {
    return {
      countries: [],
    }
  },
  computed: {
    countryProxy: {
      get() {
        return this.country
      },
      set(value) {
        this.$emit('update:country', value)
      },
    },
  },
  created() {
    this.getCountries()
  },
  methods: {
    getCountries(query) {
      this.$portalUsers.get('/internalops/azan/country', {
        params: {
          country: query,
        },
      }).then(res => {
        this.countries = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
