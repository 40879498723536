<template>
  <b-row>
    <b-col
      md="4"
    >
      <b-card header="Search Azan">
        <div>
          <azan-search
            :search-query="searchQuery"
            @search="getPrayers"
          />

          <b-button
            variant="primary"
            @click="exportPrayers"
          >
            Export
          </b-button>
        </div>
      </b-card>
    </b-col>

    <b-col
      md="8"
    >
      <b-card>
        <b-table
          :items="prayerTimes"
          :fields="fields"
          responsive
          show-empty
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { saveAs } from 'file-saver'
import AzanSearch from '../../common/components/Prayers/AzanSearch.vue'

export default {
  components: {
    AzanSearch,
  },
  data() {
    return {
      prayerTimes: [],
      searchQuery: {},
    }
  },
  computed: {
    fields() {
      return [
        { key: 'Day' },
        { key: 'Fajr' },
        { key: 'Shurouk' },
        { key: 'Duhr' },
        { key: 'Asr' },
        { key: 'Maghrib' },
        { key: 'Isha' },
      ]
    },
  },
  methods: {
    getPrayers() {
      return this.$portalUsers.post('/internalops/azan/settings', this.searchQuery).then(res => {
        const prayers = res.data.data
        this.prayerTimes = prayers
      })
    },
    exportPrayers() {
      this.$portalUsers.post('/internalops/azan/settings',
        {
          ...this.searchQuery,
          export: true,
        }, {
          responseType: 'blob',
        }).then(res => {
        const blob = new Blob([res.data])
        saveAs(blob, 'salawat.xlsx')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
